<script>
import { createBeneficiary } from "@/state/helpers";
import * as moment from "moment";
import { required, email, helpers, requiredIf } from "vuelidate/lib/validators";
// import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import Breadcrumb from '../../../components/breadcrumb.vue';
import VuePhoneNumberInput from "vue-phone-number-input";
import { parsePhoneNumber } from 'libphonenumber-js';
const alphAndSpaceValidator = helpers.regex('alphaAndSpace', /^[a-zA-Z ]*$/);
import Country from "../../../components/country.vue";
import {
  CREATE_BENEFICIARY,
  UPDATE_BENEFICIARY,
  FETCH_BANKS_LIST,
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_BENEFICIARY_DETAILS
} from "@/state/actions.type";

export default {
    components: {
        // DatePicker,
        Layout,
        Breadcrumb,
        VuePhoneNumberInput,
        Country
    },
     validations: {
      chaiBeneficiary: {
        payout_channel: { required },
        bank_account_type: { required },
        name: { required },
        email: { email },
        address: { requiredIf: requiredIf(function (nestedModel) {
            return nestedModel.payout_channel === 'PAYNAMICS_PAYOUT';
          })
        },
        bank_account_number: { required },
        bank_account_name: { required,alphAndSpaceValidator },
        bank_account_middle_name: { alphAndSpaceValidator },
        bank_account_last_name: { requiredIf: requiredIf(function (nestedModel) {
            return nestedModel.payout_channel === 'PAYNAMICS_PAYOUT';
          }),
          alphAndSpaceValidator
        },
        country: {
          requiredIf: requiredIf(function (nestedModel) {
            return nestedModel.payout_channel === 'PAYNAMICS_PAYOUT';
          })
        },
        bank_name: { requiredIf: requiredIf(function (nestedModel) {
          return !['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(nestedModel.bank_account_type)
        })},
        bank_no: { requiredIf: requiredIf(function (nestedModel) {
          return !['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(nestedModel.payout_channel === 'PAYNAMICS_PAYOUT')
        })},
        phone: { },
        fee_payer: { required }
      },
    },
    data() {
        return {
          chaiBeneficiary: {
            payout_channel: "",
            name: "",
            email: "",
            phone: "",
            address: "",
            bank_account_number: "",
            bank_account_name: "",
            bank_account_middle_name: "",
            bank_account_last_name: "",
            country: "",
            bank_name: "",
            bank_account_type: "",
            bank_no: "",
            fee_payer: "SENDER"
          },
          phoneNumberVal: null,
          beneficiaryList: [],
          submitted: false,
          phoneNumber: "",
          isEdit: false,
          defaultCountryCode: "",
        }
    },

    created() {      
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`,{
        country: [],
        paymentMethod: "",
        paymentType: "PAYOUT"
      });
      if(this.$route.query.ref) {
          this.isEdit = true;
          this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_DETAILS}`, {
              ref: this.$route.query.ref
          }).then(response => {
              if(response.content.length > 0) {
                  this.editBeneficiary(response.content[0]);
              }
          }).catch(error => {
              console.log(error);
          });

      } 

      this.defaultCountryCode = this.$store.state.auth.currentUser.country;
    },

    computed: {
        items() {
          return [
            {
              text: this.$t("menuitems.payouts.list.beneficiary"),
              to: { name: 'Vendors' }
            },
            {
              text: this.$route.name == "Update Beneficiary" ? "Update Beneficiary" : this.$t("views.payouts.create-beneficiary") ,
              active: true,
            },
          ];
        },
        payoutChannels() {
          let channels = this.$store.state.paymentChannels.channels;
          let channelList = []

          // if(channels.length == 1) {
          //   this.chaiBeneficiary.payout_channel = channels[0];
          // }
          for(let psp in channels) {
            if(channels[psp].onboarding_status.status == "Completed") {
              channelList.push(psp)
            }
          }
          return channelList;
          // return Object.keys(this.$store.state.paymentChannels.channels);
        },
        banksList() {
          return this.$store.state.beneficiary.banks_list;
        },
    },

    filters: {
        schedule_date(value) {
            return value ? moment(value).format("DD MMM, YYYY hh:mm A") : '';
        },
    },

    watch: {
      'chaiBeneficiary.payout_channel': function(value) {
        if(value) {
          this.$store.dispatch(`beneficiary/${FETCH_BANKS_LIST}`, value);
        }
        if(value == 'GBPRIMEPAY_PAYOUT') {
          this.chaiBeneficiary.bank_account_type = 'BANK';
        }
      },
      phoneNumberVal: function (val) {
        this.chaiBeneficiary.phone = (val && val.countryCallingCode && val.phoneNumber) ? `+${val.countryCallingCode}${val.phoneNumber.replace(/ /g,'')}` : "";
      },
    },

    methods: {
       ...createBeneficiary,

        validateState(name) {
          const { $dirty, $error } = this.$v.chaiBeneficiary[name];
          return $dirty ? !$error : null;
        },

        resetBeneficiary() {
          this.chaiBeneficiary = {
            payout_channel: "",
            name: "",
            email: "",
            phone: "",
            address: "",
            bank_account_number: "",
            bank_account_name: "",
            bank_account_middle_name: "",
            bank_account_last_name: "",
            country: "",
            bank_name: "",
            bank_account_type: "",
            bank_no: "",
            fee_payer: "SENDER"
          };
          this.phoneNumberVal = null;
          this.phoneNumber = "";
          this.isEdit = false;
        },
        countryChanged(value) {
          this.chaiBeneficiary.country = value;
        },
        editBeneficiary(item) {
          if(this.isMasterMerchant) {
            return;
          }
          this.chaiBeneficiary = {
            payout_channel: item.payout_channel,
            name: item.name,
            email: item.email,
            address: item.address,
            bank_account_number: item.bank_accounts.account_number,
            bank_account_name: item.bank_accounts.account_name,
            bank_account_middle_name: item.bank_accounts.account_middle_name,
            bank_account_last_name: item.bank_accounts.account_last_name,
            country: item.bank_accounts.country,
            bank_name: item.bank_accounts.bank_name,
            bank_account_type: item.bank_accounts.account_type,
            bank_no: item.bank_accounts.bank_no,
            fee_payer: item.fee_payer
          };

          this.phoneNumber = item.phone;

          if(item.phone) {
            const phoneNumber = parsePhoneNumber(item.phone, this.defaultCountryCode);
            if(phoneNumber) {
              this.defaultCountryCode = phoneNumber.country;
              this.phoneNumberVal = {
                countryCallingCode: phoneNumber.countryCallingCode,
                countryCode: phoneNumber.country,
                formatInternational: phoneNumber.formatInternational(),
                formatNational: phoneNumber.formatNational(),
                formattedNumber: phoneNumber.number,
                isValid: phoneNumber.isValid(),
                nationalNumber: phoneNumber.nationalNumber,
                phoneNumber: phoneNumber.number
              }
            }
          }

          this.isEdit = true;
        },

        addVendors() {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid || (this.chaiBeneficiary.phone && this.phoneNumberVal && !this.phoneNumberVal.isValid)) {
            return;
          }

          const payload = {
            payout_channel: this.chaiBeneficiary.payout_channel,
            name: this.chaiBeneficiary.name,
            email: this.chaiBeneficiary.email,
            phone: this.chaiBeneficiary.phone,
            address: this.chaiBeneficiary.address,
            bank_account_number: this.chaiBeneficiary.bank_account_number,
            bank_account_name: this.chaiBeneficiary.bank_account_name,
            bank_name: this.chaiBeneficiary.bank_name,
            bank_account_middle_name: this.chaiBeneficiary.bank_account_middle_name,
            bank_account_last_name: this.chaiBeneficiary.bank_account_last_name,
            country: this.chaiBeneficiary.country,
            bank_no: this.chaiBeneficiary.bank_no,
            bank_account_type: this.chaiBeneficiary.bank_account_type,
            fee_payer: this.chaiBeneficiary.payout_channel == 'APPOTAPAY_PAYOUT' ? this.chaiBeneficiary.fee_payer : "SENDER",
          };

          const createOrEdit = this.isEdit ? `beneficiary/${UPDATE_BENEFICIARY}` : `beneficiary/${CREATE_BENEFICIARY}`;

          this.$store.dispatch(createOrEdit, payload)
          .then((result) => {
            this.$notify({
              type: "success",
              text: result.message,
              closeOnClick: true,
            });
            setTimeout(() => {
              this.$router.push({name: 'Vendors'});
            }, 500);

            this.submitted = false;
            this.resetBeneficiary();
            this.$v.$reset();
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              text: error.message,
              closeOnClick: true,
            });

          });
        },

        accNumberLabel() {
          if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
            if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
              return 'Promptpay National ID';
            } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
              return 'Promptpay Mobile number';
            }
          }
          return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.card-no") : this.$t("views.payouts.bank-acc-no")
        },
        accNumberError() {
          if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
            if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
              return 'Benefactor Promptpay National ID is required';
            } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
              return 'Benefactor Promptpay Mobile number is required';
            }
          }
          return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.required_field_msg.card-no") : this.$t("views.payouts.required_field_msg.bank-acc-no");
        },
        accNameLabel() {
          if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
            if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
              return 'Promptpay National ID Name';
            } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
              return 'Promptpay Account Name';
            }
          } else if(this.chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT') {
            return 'Bank Account First Name';
          }
          return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.card_holder_name") : this.$t("views.payouts.bank-acc-name")
        },
        accNameError() {
          if(this.chaiBeneficiary.payout_channel === 'BBL_PAYOUT') {
            if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_NATID') {
              return 'Benefactor Promptpay National ID Name is required';
            } else if(this.chaiBeneficiary.bank_account_type === 'PROMPTPAY_MOBILE'){
              return 'Benefactor Promptpay Account Name is required';
            }
          } else if(this.chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT') {
            return 'Bank Account First Name is required';
          }
          return this.chaiBeneficiary.bank_account_type == 'CARD' ? this.$t("views.payouts.required_field_msg.card_holder_name") : this.$t("views.payouts.required_field_msg.bank_acc_name");
        },
        selectbankcode(){
          this.chaiBeneficiary.bank_no = event.target.options[event.target.selectedIndex].dataset.key;
        },
        currentDate(){
          return moment().format("DD MMM,YYYY")
        },
    }
};
</script>
<template>
<Layout>
    <div class="d-flex" style="height: 44px;">
      <breadcrumb :items="items" :goBackTo="{ name: 'Vendors' }" />
      <div class="ml-auto">
        <router-link class="text-chai font-16px" :to="{ name: 'Vendors' }">
          <b-btn
            variant="white"
            style="padding: 13px 16px;height: 44px;border: 1px solid #000;margin-right: 8px;"
          >
            cancel
          </b-btn>
        </router-link>
        <b-btn
          variant="primary"
          @click="addVendors"
          style="height: 44px;padding: 13px 16px;"
        >{{ $t('button.save') }}</b-btn>
      </div>
    </div>
     <div style="margin-top: 25px;">
      <form class="row" id="modal">
        <div class="col-lg-8 col-8">
          <div class="top-section">
            <div class="top-section-header">
                <div style="font-size: 16px;color: #000000;font-weight: 350;">{{ $route.name == "Update Beneficiary" ? "Update Beneficiary" : "Add New Beneficiary" }}</div>
                <div style="font-size: 12px;color: #00000080;margin-top: 10px;font-weight: 325;">{{ $route.name == "Update Beneficiary" ? "Update Beneficiary" : "Add New Beneficiary" }}.</div>
            </div>
            <div class="top-section-body" style="padding: 20px 20px 20px 10px;">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="select_psp">
                    {{ $t("common.psp") }}
                    <span class="text-danger mr-1">*</span>
                  </label>

                  <b-form-select
                    name="select_psp"
                    v-model="chaiBeneficiary.payout_channel"
                    :options="payoutChannels"
                    :disabled="isEdit"
                    :class="{
                      'is-invalid': submitted && $v.chaiBeneficiary.payout_channel.$error,
                    }"
                  >
                    <template #first>
                      <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.chaiBeneficiary.payout_channel.required"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.psp") }}
                  </div>
                  <div
                  style="font-size: 12px;font-weight: 325;line-height: normal;padding-top: 5px;"
                    v-if="payoutChannels.length == 0"
                  >
                    Please configure a Payout Channel at <span class="text-chai" style="font-weight: 350;cursor: pointer;" @click="$router.push({name: 'Payment Channels', query: { tab: 'Payout Channels' }})">Payout Channels section</span> or Kindly contact business team of PortOne for more details.
                  </div>
                </div>

                <div class="form-group" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">
                  <label for="country">Country</label>
                  <Country name="country" :country="chaiBeneficiary.country" @country-changed="countryChanged" :invalid="submitted && $v.chaiBeneficiary.country.$error"/>
                  <div
                    v-if="submitted && $v.chaiBeneficiary.country.$error"
                    style="margin-top: 0.25rem; font-size: 80%; color: #ff3d60;"
                  >
                    Country is required
                  </div>
                </div>
                <div v-if="chaiBeneficiary.payout_channel !== 'GBPRIMEPAY_PAYOUT'" class="form-group">
                  <label for="bank_account_type">
                    {{ $t("views.payouts.bank_account_type") }}
                    <span class="text-danger mr-1">*</span>
                  </label>

                  <b-form-select
                    name="bank_account_type"
                    v-model="chaiBeneficiary.bank_account_type"
                    :disabled="isEdit"
                    :class="{
                      'is-invalid': submitted && $v.chaiBeneficiary.bank_account_type.$error,
                    }">
                    <b-form-select-option :value="null">{{ $t('common.select_option') }}</b-form-select-option>
                    <b-form-select-option value="BANK">Bank Account</b-form-select-option>
                    <b-form-select-option v-if="chaiBeneficiary.payout_channel !== 'BBL_PAYOUT'" value="CARD">Card</b-form-select-option>
                    <b-form-select-option v-if="chaiBeneficiary.payout_channel === 'BBL_PAYOUT'" value="PROMPTPAY_NATID">Promptpay - National ID</b-form-select-option>
                    <b-form-select-option v-if="chaiBeneficiary.payout_channel === 'BBL_PAYOUT'" value="PROMPTPAY_MOBILE">Promptpay - Mobile number</b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.chaiBeneficiary.bank_account_type.required"
                    class="invalid-feedback"
                  >
                    {{ $t('views.payouts.required_field_msg.bank_account_type') }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="name">
                    {{ $t("views.payouts.name") }}
                    <span class="text-danger mr-1">*</span>
                  </label>

                  <input
                    id="name"
                    v-model="chaiBeneficiary.name"
                    type="text"
                    class="form-control"
                    placeholder="John Doe"
                    :class="{
                      'is-invalid': submitted && $v.chaiBeneficiary.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.chaiBeneficiary.name.required"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.name") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="email">
                    {{ $t("views.payouts.email") }}
                    <!-- <span class="text-danger mr-1">*</span> -->
                  </label>
                  <input
                    type="email"
                    v-model="chaiBeneficiary.email"
                    class="form-control"
                    placeholder="xyz@mycompanysite.com"
                    :class="{
                      'is-invalid': submitted && $v.chaiBeneficiary.email.$error,
                    }"
                  />
                  <!-- <div
                    v-if="submitted && !$v.chaiBeneficiary.email.required"
                    class="invalid-feedback"
                  >
                    Email is required
                  </div> -->
                  <div
                    v-if="submitted && !$v.chaiBeneficiary.email.email"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.email") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="phone">
                    {{ $t("views.payouts.phone") }}
                  </label>
                  <VuePhoneNumberInput
                    v-model="phoneNumber"
                    id="VuePhoneNumber"
                    @update="phoneNumberVal = $event"
                    valid-color="#1cbb8c"
                    :default-country-code="defaultCountryCode"
                    :class="(submitted && $v.chaiBeneficiary.phone.$error) || (submitted && chaiBeneficiary.phone && phoneNumberVal && !phoneNumberVal.isValid) ? `border border-danger rounded` : ``"
                    :preferred-countries="['VN', 'TH', 'ID', 'SG', 'KR', 'PH', 'IN', 'MY']"
                  />
                  <div
                    v-if="submitted && $v.chaiBeneficiary.phone.$error"
                    class="text-danger font-size-12"
                  >
                    {{ $t("views.payouts.required_field_msg.phone") }}
                  </div>
                  <div
                    v-else-if="submitted && chaiBeneficiary.phone && phoneNumberVal && !phoneNumberVal.isValid"
                    class="text-danger font-size-12"
                  >
                    Invalid Phone Number.
                  </div>
                </div>
                <div class="form-group">
                  <label for="address">
                    {{ $t("views.payouts.address") }}
                    <span class="text-danger mr-1" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">*</span>
                  </label>
                  <input
                    v-model="chaiBeneficiary.address"
                    type="tel"
                    class="form-control"
                    :placeholder="$t('views.payouts.address')"
                    :class="{
                      'is-invalid': submitted && $v.chaiBeneficiary.address.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.chaiBeneficiary.address.required"
                    class="invalid-feedback"
                  >
                    Address is required
                  </div>
                </div>

                <div class="form-group">
                  <label for="bankAccountNumber">
                    {{ accNumberLabel() }}
                    <span class="text-danger mr-1">*</span>
                  </label>
                  <b-form-input
                    v-model="$v.chaiBeneficiary.bank_account_number.$model"
                    id="bankAccountNumber"
                    type="number"
                    step="any"
                    class="form-control"
                    :placeholder="accNumberLabel()"
                    :state='validateState("bank_account_number")'
                    :disabled="isEdit"
                    :readonly="isEdit"
                    :class="{
                      'is-invalid':
                        submitted && $v.chaiBeneficiary.bank_account_number.$error,
                    }"
                  />
                  <div
                    v-if="
                      submitted && $v.chaiBeneficiary.bank_account_number.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiBeneficiary.bank_account_number.required">
                      {{ accNumberError() }}
                    </span>
                  </div>
                </div>
                
                <div class="form-group">
                  <label for="bankAccountName">
                    {{ accNameLabel() }}
                    <span class="text-danger mr-1">*</span>
                  </label>
                  <b-form-input
                    id="bankAccountName"
                    type="text"
                    class="form-control"
                    :disabled="isEdit"
                    :readonly="isEdit"
                    :placeholder="accNameLabel()"
                    v-model="$v.chaiBeneficiary.bank_account_name.$model"
                    :class="{
                      'is-invalid':
                        submitted && $v.chaiBeneficiary.bank_account_name.$error,
                    }"
                  />
                  <div
                    v-if="
                      submitted && $v.chaiBeneficiary.bank_account_name.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiBeneficiary.bank_account_name.required">
                      {{ accNameError() }}
                    </span>
                  </div>
                </div>
                <div class="form-group" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">
                  <label for="bankAccountMiddleName">
                    <i class="mdi mdi-account-circle"></i>
                    Bank Account Middle Name
                  </label>
                  <b-form-input
                    id="bankAccountMiddleName"
                    type="text"
                    class="form-control"
                    :disabled="isEdit"
                    :readonly="isEdit"
                    placeholder="Bank Account Middle Name"
                    v-model="$v.chaiBeneficiary.bank_account_middle_name.$model"
                    :class="{
                      'is-invalid':
                        submitted && $v.chaiBeneficiary.bank_account_middle_name.$error,
                    }"
                  />
                  <div
                    v-if="
                      submitted && $v.chaiBeneficiary.bank_account_middle_name.$error
                    "
                    class="invalid-feedback"
                  >
                    Invalid bank account middle name
                  </div>
                </div>
                <div class="form-group" v-if="chaiBeneficiary.payout_channel === 'PAYNAMICS_PAYOUT'">
                  <label for="bankAccountLastName">
                    <i class="mdi mdi-account-circle"></i>
                      Bank Account Last Name
                    <span class="text-danger mr-1">*</span>
                  </label>
                  <b-form-input
                    id="bankAccountLastName"
                    type="text"
                    class="form-control"
                    :disabled="isEdit"
                    :readonly="isEdit"
                    placeholder="Bank Account Last Name"
                    v-model="$v.chaiBeneficiary.bank_account_last_name.$model"
                    :class="{
                      'is-invalid':
                        submitted && $v.chaiBeneficiary.bank_account_last_name.$error,
                    }"
                  />
                  <div
                    v-if="
                      submitted && $v.chaiBeneficiary.bank_account_last_name.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.chaiBeneficiary.bank_account_last_name.required">
                      Bank account last name is required
                    </span>
                  </div>
                </div>

                <div v-if="!['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(chaiBeneficiary.bank_account_type)" class="form-group">
                  <label for="bankName">
                    {{ $t("views.payouts.bank-name") }}
                    <span class="text-danger mr-1">*</span>
                  </label>
                  <select
                    id="bankName"
                    name="bankName"
                    class="custom-select"
                    :placeholder="$t('views.payouts.bank-name')"
                    v-model="chaiBeneficiary.bank_name"
                    :disabled="isEdit"
                    @change="selectbankcode"
                    :class="{
                      'is-invalid':
                        submitted && $v.chaiBeneficiary.bank_name.$error,
                    }"
                  >

                <option :key="index" v-for="(option, index) in banksList" :data-key="option.bank_no" :value="option.bank_name" >{{ option.bank_name }}</option>
                  </select>
                  <div
                    v-if="submitted && !$v.chaiBeneficiary.bank_name.required"
                    class="invalid-feedback"
                  >
                  {{ $t("views.payouts.required_field_msg.bank_name") }}
                  </div>
                </div>

                <div class="form-group" v-if="!['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(chaiBeneficiary.bank_account_type)">
                  <label for="bankNumber">
                    {{ $t("views.payouts.bank-number") }}
                    <span class="text-danger mr-1">*</span>
                  </label>
                  <input
                    id="bankNumber"
                    type="text"
                    class="form-control"
                    :disabled="isEdit"
                    :readonly="isEdit"
                    :placeholder="$t('views.payouts.bank-number')"
                    v-model="chaiBeneficiary.bank_no"
                    :class="{
                      'is-invalid': submitted && $v.chaiBeneficiary.bank_no.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.chaiBeneficiary.bank_no.required"
                    class="invalid-feedback"
                  >
                  {{ $t("views.payouts.required_field_msg.bank_no") }}
                  </div>
                </div>

                <div v-if="chaiBeneficiary.payout_channel == 'APPOTAPAY_PAYOUT'" class="form-group">
                  <label for="fee_payer">
                    {{ $t("views.payouts.fee_payer") }}
                    <span class="text-danger mr-1">*</span>
                  </label>
                  <b-form-select
                    name="fee_payer"
                    v-model="chaiBeneficiary.fee_payer"
                    :class="{
                      'is-invalid': submitted && $v.chaiBeneficiary.fee_payer.$error,
                    }">
                    <b-form-select-option :value="null">{{ $t("common.select_option") }}</b-form-select-option>
                    <b-form-select-option value="SENDER">SENDER</b-form-select-option>
                    <b-form-select-option value="RECEIVER">RECEIVER</b-form-select-option>
                  </b-form-select>

                  <div
                    v-if="submitted && !$v.chaiBeneficiary.fee_payer.required"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.fee_payer") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="col-lg-4 col-4">
            <div style="padding: 25px;box-shadow: 0px 4px 15px 1px rgba(220, 218, 213, 0.30);border-radius: 12px;border: 1px rgba(220, 218, 213, 0.50) solid;">
                <p style="font-size: 20px;color: #FC6B2D; margin-bottom: 25px;}">Summary</p>
                <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px; margin-top: 25px;"></div>
                <div style="margin-bottom: 16px;" class="summary-details-section">
                  <div class="summary-name">PSP</div>
                  <div class="summary-details">{{chaiBeneficiary.payout_channel}}</div>
                </div>
                <div style="margin-bottom: 16px;" class="summary-details-section">
                  <div class="summary-name">Beneficiary Name</div>
                  <div class="summary-details">{{chaiBeneficiary.name}}</div>
                </div>
                <div style="margin-bottom: 16px;" class="summary-details-section">
                  <div class="summary-name">Email</div>
                  <div class="summary-details">{{chaiBeneficiary.email}}</div>
                </div>
                <div style="margin-bottom: 16px;" class="summary-details-section">
                  <div class="summary-name">Contact Number</div>
                  <div class="summary-details">{{chaiBeneficiary.phone}}</div>
                </div>
                <div class="summary-details-section">
                  <div class="summary-name">Address</div>
                  <div class="summary-details">{{chaiBeneficiary.address}}</div>
                </div>
                
                <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px; margin-top: 25px;"></div>
                <p style="font-size: 14px;color: #000000; margin-bottom: 12px;}">Bank Details</p>
                <div class="summary-details-section">
                    <div class="summary-name">Account Number</div>
                    <div class="summary-details">{{  chaiBeneficiary.bank_account_number }}</div>
                </div>
                <div class="summary-details-section" style="margin-top: 16px;">
                    <div class="summary-name">Account Name</div>
                    <div class="summary-details">{{chaiBeneficiary.bank_account_name}}</div>
                </div>
                <div class="summary-details-section" style="margin-top: 16px;">
                    <div class="summary-name">Bank Name</div>
                    <div class="summary-details"> {{  chaiBeneficiary.bank_name }}</div>
                </div>
                <div class="summary-details-section" style="margin-top: 16px;">
                    <div class="summary-name">Bank Number</div>
                    <div class="summary-details"> {{  chaiBeneficiary.bank_no }}</div>
                </div>
            </div>
        </div>
      </form>
     </div>
  </Layout>
</template>

<style scoped>
:disabled {
  background: #f3f6f9;
  color: #9da0af;
}
  .top-section-header{
  background: #dcdad54d;
  padding: 18px 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px #DCDAD5 solid;
}
.top-section{
    box-shadow: 0px 4px 12px 1px rgba(220, 218, 213, 0.30);
    border-radius: 15px;
}
::v-deep .top-section label{
    color: black;
    font-size: 14px;
    margin-bottom: 10px;
}
.top-section .form-group{
    margin-bottom: 20px;
}
::v-deep .top-section select, ::v-deep .top-section input, ::v-deep .top-section textarea{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: auto;
}
.top-section select{
    padding-top: 13.5px;
    padding-bottom: 13.5px;
}
.top-section .flag_images{
    position: absolute;
    width: 28px;
    top: 36px;
    left: 20px;
    border-radius: 4px;
}
.summary-details-section .summary-name{
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    line-height: 20px;
}
.summary-details-section .summary-details{
    font-size: 14px;
    line-height: 20px;
}
</style>